import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';

const TypedText = () => {
  const typedElement = useRef(null);

  useEffect(() => {
    const options = {
      strings: typedElement.current.getAttribute('data-typed-items').split(','),
      loop: true,
      typeSpeed: 150,
      backSpeed: 80,
      backDelay: 2000,
    };

    const typed = new Typed(typedElement.current, options);

    return () => {
      typed.destroy(); // Cleanup the Typed instance on component unmount
    };
  }, []);

  return (
    <span
      ref={typedElement}
      className="typed"
      data-typed-items="Designer, Developer, Freelancer, Youtuber, Student"
    />
  );
};

export default TypedText;
