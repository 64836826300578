import React from 'react'
import './about.css'
import ME from '../../assets/me-about.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
     <h5>Get To Know</h5>
     <h2>About Me</h2>

     <div className='container about_container'>
      <div className="about_me">
        <div className="about_me-image">
          <img src={ME} alt="About" />
        </div>
      </div>

      <div className="about_content">
        <div className="about_cards">
          <article className='about_card'>
            <FaAward className='about_icon'/>
            <h5>Experience</h5>
            <small>3+ Year Working</small>
          </article>

          <article className='about_card'>
            <FiUsers className='about_icon'/>
            <h5>Clients</h5>
            <small>7+ Clients</small>
          </article>

          <article className='about_card'>
            <VscFolderLibrary className='about_icon'/>
            <h5>Project</h5>
            <small>15+ Project Completed</small>
          </article>
        </div>

        <p>With a versatile skill set, I excel as a designer and developer with around 3+ years of working experience. My expertise spans multiple domains, including freelancing and content creation on YouTube. Balancing work and studies, I bring creativity and technical knowledge to every project, making an impact as a designer, developer, freelancer, YouTuber, and student.</p>

           <a href="#contact" className='btn btn-primary'>Let's Talk</a>

      </div>
     </div>
    </section>
  )
}

export default About
